import styled, { css } from "styled-components";

import { FaWeightScale } from "react-icons/fa6";
import { GiNightSleep } from "react-icons/gi";
import { IoFitness } from "react-icons/io5";
import { MdSick } from "react-icons/md";
import { RiRestaurant2Line } from "react-icons/ri";
import Section from "components/Section";
import { TbPill } from "react-icons/tb";
import { WorkoutPlanType } from "types";
import { getWorkoutProgress } from "utils";

const Wrapper = styled.div`
  width: 100%;
  margin: auto;

  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 20px;

  padding: 5vh 2%;
  padding-bottom: 10vh;
`;

const Header = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;

  text-align: center;
  font-size: 20px;
  color: ${(props) => props.theme.colors.text}88;

  div {
    min-width: 105px;
    font-weight: 500;
    color: ${(props) => props.theme.colors.text};
    margin: 0 5px;
  }
`;

const InfoSection = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
  padding: 20px 30px;
  border: 1px solid ${(props) => props.theme.colors.mainDark2};
  border-radius: 4px;
`;

const InfoIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  transition: all 0.2s ease;

  color: ${(props) => props.theme.colors.mainDark};

  svg {
    font-size: min(30px, 8vw);
  }
`;

const InfoContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
`;

const InfoRow = styled.div`
  color: ${(props) => props.theme.colors.text}AA;

  strong {
    font-weight: 500;
    color: ${(props) => props.theme.colors.text};
    margin-left: 10px;
  }
`;

const InfoHighlighted = styled.span`
  font-size: 24px;
  font-weight: 500;
  color: ${(props) => props.theme.colors.main};
  margin-left: 10px;
`;

const Day = styled.div<{ $isSickDay?: boolean }>`
  display: flex;
  align-items: flex-start;
  justify-content: stretch;
  flex-wrap: wrap;
  gap: max(5px, 2%);

  ${(props) =>
    props.$isSickDay
      ? css`
          background: ${(props) => props.theme.colors.warning}08;
        `
      : css``}

  & > * {
    min-width: 200px;
  }
`;

const Text = styled.div`
  white-space: pre-wrap;
  line-height: 1.5;
  color: ${(props) => props.theme.colors.text}DD;
`;

const SkipText = styled.div`
  white-space: pre-wrap;
  line-height: 1.5;
  color: ${(props) => props.theme.colors.warning}DD;
`;

const SickSection = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  gap: 10px;

  color: ${(props) => props.theme.colors.warning};
  background: ${(props) => props.theme.colors.warning}08;
  border-radius: 4px;
  padding: 10px 16px;
  padding-bottom: 10px;
`;

const SickIcon = styled.div`
  svg {
    font-size: 22px;
    margin-top: 4px;
  }
`;

const SickText = styled.div`
  font-size: 18px;
  font-weight: 500;
`;

const WorkoutPlanOverview = (props: { dataWorkoutPlan: WorkoutPlanType }) => {
  const { dataWorkoutPlan } = props;

  const weightDiff =
    Math.round(
      (dataWorkoutPlan.weight?.endKg - dataWorkoutPlan.weight?.startKg) * 10
    ) / 10;

  return (
    <Wrapper>
      <Header>
        <div>{dataWorkoutPlan.dates?.start}</div>
        {" - "}
        <div>{dataWorkoutPlan.dates?.end}</div>
      </Header>

      <InfoSection>
        <InfoIcon>
          <FaWeightScale />
        </InfoIcon>

        <InfoContent>
          <InfoRow>
            Počáteční váha:{" "}
            <strong>{dataWorkoutPlan.weight?.startKg ?? "?"} kg</strong>
          </InfoRow>

          <InfoRow>
            Konečná váha:{" "}
            <strong>{dataWorkoutPlan.weight?.endKg ?? "?"} kg</strong>
          </InfoRow>

          <InfoRow>
            Rozdíl:{" "}
            <InfoHighlighted>
              {!isNaN(weightDiff) && weightDiff > 0 ? "+" : ""}
              {isNaN(weightDiff) ? "?" : weightDiff} kg
            </InfoHighlighted>
          </InfoRow>
        </InfoContent>
      </InfoSection>

      {dataWorkoutPlan.days?.map((day, i) => {
        return (
          <Section key={i} level={0} label={day.name} isOpen>
            {day.isSickDay ? (
              <SickSection>
                <SickIcon>
                  <MdSick />
                </SickIcon>
                <SickText>Nemoc</SickText>
              </SickSection>
            ) : null}

            <Day $isSickDay={day.isSickDay}>
              <Section
                level={1}
                label={`Jídlo`}
                isOpen
                icon={RiRestaurant2Line}
              >
                {day.meals?.map((meal, j) => {
                  const isCompleted =
                    meal.customOption?.isCompleted ||
                    meal.options?.filter((m) => m.isCompleted).length > 0;

                  return (
                    <Section key={j} level={2} label={meal.name} isOpen>
                      {meal.options
                        ?.filter((m) => m.isCompleted)
                        ?.map((mealOption, k) => (
                          <Text key={k}>
                            {mealOption.ingredients.join("\n")}
                          </Text>
                        ))}

                      {meal.customOption?.isCompleted ? (
                        <Text>{meal.customOption.text}</Text>
                      ) : null}

                      {!isCompleted ? <SkipText>Vynecháno</SkipText> : null}
                    </Section>
                  );
                })}
              </Section>

              <Section level={1} label={`Suplementy`} isOpen icon={TbPill}>
                {day.supplements?.map((supplement, j) => {
                  const isCompleted =
                    supplement.customOption?.isCompleted ||
                    supplement.options?.filter((m) => m.isCompleted).length > 0;

                  return (
                    <Section key={j} level={2} label={supplement.name} isOpen>
                      {supplement.options
                        ?.filter((m) => m.isCompleted)
                        ?.map((supplementOption, k) => (
                          <Text key={k}>
                            {supplementOption.ingredients.join("\n")}
                          </Text>
                        ))}

                      {supplement.customOption?.isCompleted ? (
                        <Text>{supplement.customOption.text}</Text>
                      ) : null}

                      {!isCompleted ? <SkipText>Vynecháno</SkipText> : null}
                    </Section>
                  );
                })}
              </Section>

              {day.exercises?.length ? (
                <Section level={1} label={`Trénink`} isOpen icon={IoFitness}>
                  {getWorkoutProgress(day) === 0 ? (
                    <SkipText>Vynecháno</SkipText>
                  ) : (
                    <>
                      {day.exercises?.map((exercise, j) => {
                        return (
                          <Section
                            key={j}
                            level={2}
                            label={exercise.name}
                            isOpen
                          >
                            {exercise.sets?.map((set, k) => (
                              <Text key={k}>
                                {exercise.isCardio
                                  ? `${set.durationMinutes}min`
                                  : `${set.repCount}x`}{" "}
                                {set.value ?? (exercise.isCardio ? "" : "??")}
                              </Text>
                            ))}
                          </Section>
                        );
                      })}
                    </>
                  )}
                </Section>
              ) : (
                <Section
                  level={1}
                  label={`Rest Day`}
                  isOpen
                  icon={GiNightSleep}
                ></Section>
              )}
            </Day>
          </Section>
        );
      })}
    </Wrapper>
  );
};

export default WorkoutPlanOverview;
