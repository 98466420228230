import { FileCategory, WorkoutPlanType } from "types";

import { FaFile } from "react-icons/fa";
import LinkBox from "components/LinkBox";
import Section from "../Section";
import { getMealNameFromId } from "utils";
import styled from "styled-components";

const Wrapper = styled.div`
  width: 100%;
  margin: auto;

  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 60px;

  padding: 5vh 2%;
  padding-bottom: 10vh;
`;

const Header = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;

  text-align: center;
  font-size: 20px;
  color: ${(props) => props.theme.colors.text}88;

  div {
    min-width: 105px;
    font-weight: 500;
    color: ${(props) => props.theme.colors.text};
    margin: 0 5px;
  }
`;

const Content = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 40px;

  & > * {
    min-width: 280px;
  }
`;

const Text = styled.div`
  white-space: pre-wrap;
  line-height: 1.5;
  color: ${(props) => props.theme.colors.text}DD;
`;

const WorkoutPlanMeals = (props: { dataWorkoutPlan: WorkoutPlanType }) => {
  const { dataWorkoutPlan } = props;

  const files = dataWorkoutPlan?.files?.filter((f) =>
    f.categories?.includes(FileCategory.mealPlan)
  );

  return (
    <Wrapper>
      <Header>Stravovací plán</Header>

      {files?.map((f, i) => (
        <LinkBox
          key={i}
          label={f.name}
          to={f.link}
          icon={FaFile}
          openInNewTab
        />
      ))}

      <Content>
        {dataWorkoutPlan.mealSuggestions?.map((s, i) => (
          <Section
            key={i}
            level={0}
            label={s.mealIds.map((id) => getMealNameFromId(id)).join(", ")}
            isOpen
          >
            {s.suggestions?.map((m, j) => (
              <Section key={j} level={2} label={`Varianta ${j + 1}`} isOpen>
                <Text>{m}</Text>
              </Section>
            ))}
          </Section>
        ))}
      </Content>
    </Wrapper>
  );
};

export default WorkoutPlanMeals;
