/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState } from "react";

import { DataType } from "types";
import { fetchPublicData } from "utils/google-drive/public";
import { useGlobalStorage } from "context/GlobalStorage";

export const usePublicGoogleData = (props: { googleFileId: string }) => {
  const { googleFileId } = props;

  const { fns } = useGlobalStorage();

  const [isLoading, setIsLoading] = useState(true);
  const [publicData, setPublicData] = useState<DataType>();

  useEffect(() => {
    if (googleFileId) {
      const asyncFunc = async () => {
        try {
          const data = await fetchPublicData({ fileId: googleFileId });

          if (data) {
            setPublicData(data);
          } else {
            throw new Error("Nepodařilo se načíst data.");
          }
        } catch (error) {
          fns.handleError({
            title: "Google Drive chyba",
            error,
          });
        }

        setIsLoading(false);
      };

      asyncFunc();
    }
  }, [googleFileId]);

  return { isLoading, publicData };
};
