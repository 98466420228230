const downloadFile = (data: BlobPart, filename: string, type: string) => {
  const blob = new Blob([data], { type });
  const url = URL.createObjectURL(blob);

  const link = document.createElement("a");
  link.href = url;
  link.download = filename;

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(url);
};

export const downloadJsonFile = (props: { data: object; filename: string }) => {
  const { data, filename } = props;
  const json = JSON.stringify(data, null, 2);
  downloadFile(json, filename, "application/json");
};

export const downloadTxtFile = (props: { data: string; filename: string }) => {
  const { data, filename } = props;
  downloadFile(data, filename, "text/plain");
};
