import LayoutPublic from "layouts/LayoutPublic";
import LoadingScreen from "components/LoadingScreen";
import WorkoutPlanOverview from "components/WorkoutPlanOverview";
import { useParams } from "react-router-dom";
import { usePublicGoogleData } from "hooks/usePublicGoogleData";

const PublicMeals = () => {
  let { googleFileId } = useParams<{
    googleFileId: string;
  }>();

  if (!googleFileId) {
    googleFileId = "1RryzJDEwV0sck8cUk1XiIJgrfb9j_p3p"; // TODO - MOVE TO .ENV - SHARED LINK ID TO MAIN data.json
  }

  const { isLoading, publicData } = usePublicGoogleData({ googleFileId });

  return isLoading ? (
    <LoadingScreen />
  ) : (
    <LayoutPublic>
      {publicData?.workoutPlans[0] ? (
        <WorkoutPlanOverview dataWorkoutPlan={publicData?.workoutPlans[0]} />
      ) : null}
    </LayoutPublic>
  );
};

export default PublicMeals;
