import {
  getMealsProgress,
  getSupplementsProgress,
  getWorkoutProgress,
} from "utils";

import { DayType } from "types";
import LinkBox from "components/LinkBox";
import { MdOutlineSick } from "react-icons/md";
import { PageSlugs } from "pages";
import { theme } from "styles";

const DayLink = (props: { workoutId: string; day: DayType }) => {
  const { workoutId, day } = props;

  const progressMeals = getMealsProgress(day);
  const progressSupplements = getSupplementsProgress(day);
  const progressWorkout = getWorkoutProgress(day);

  return (
    <LinkBox
      label={day.name}
      to={`${PageSlugs.day
        .replace(":workoutId", workoutId)
        .replace(":dayId", day.id)}`}
      progressData={{
        meals: progressMeals,
        supplements: progressSupplements,
        ...(day.exercises?.length
          ? { workout: progressWorkout }
          : { restDay: 1 }),
      }}
      icon={day.isSickDay ? MdOutlineSick : undefined}
      iconColor={day.isSickDay ? theme.colors.warning : undefined}
      labelColor={day.isSickDay ? theme.colors.warning : undefined}
    />
  );
};

export default DayLink;
