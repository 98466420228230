export * from "./exercise";
export * from "./exerciseTime";
export * from "./workout";
export * from "./meals";
export * from "./supplements";
export * from "./files";
export * from "./local-storage";
export * from "./google-drive";

export const generateUniqueId = () => {
  return `${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;
};
