import {
  getMealsProgress,
  getSupplementsProgress,
  getWorkoutProgress,
} from "utils";

import Button from "components/Button";
import DayEditor from "components/DayEditor";
import DayTile from "components/DayTile";
import { GiNightSleep } from "react-icons/gi";
import { IoFitness } from "react-icons/io5";
import LayoutMain from "layouts/LayoutMain";
import { MdModeEdit } from "react-icons/md";
import { MdSick } from "react-icons/md";
import NoDataScreen from "components/NoDataScreen";
import PageHeader from "components/PageHeader";
import { PageSlugs } from "pages";
import { RiRestaurant2Line } from "react-icons/ri";
import { TbPill } from "react-icons/tb";
import styled from "styled-components";
import { useDay } from "hooks/useDay";
import { useGlobalStorage } from "context/GlobalStorage";
import { useParams } from "react-router-dom";

const Wrapper = styled.div`
  flex: 1;
  height: 100%;
  box-sizing: border-box;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 10px;
`;

const Info = styled.p`
  flex: 1;
  color: ${(props) => props.theme.colors.text}AA;
  font-size: 16px;
  white-space: pre-wrap;
`;

const Tiles = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
  gap: 10px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
  gap: 10px;
`;

const Footer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  gap: 10px;
  padding-bottom: 10px;
`;

const SickSection = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;

  background: ${(props) => props.theme.colors.warning}55;
  color: ${(props) => props.theme.colors.background};
  border-radius: 4px;
  padding: 10px 20px;
`;

const SickIcon = styled.div`
  svg {
    font-size: 34px;
    margin-top: 4px;
    margin-right: 8px;
  }
`;

const SickText = styled.div`
  font-size: 20px;
  font-weight: 400;
`;

const DayPage = () => {
  const { workoutId, dayId } = useParams<{
    workoutId: string;
    dayId: string;
  }>();

  const { fns } = useGlobalStorage();
  const day = useDay({ workoutId, dayId });

  if (!day) {
    return <NoDataScreen />;
  }

  const isRestDay = !day.exercises?.length;

  const progressMeals = getMealsProgress(day);
  const progressSupplements = getSupplementsProgress(day);
  const progressWorkout = getWorkoutProgress(day);

  //
  // EDITOR
  //
  const editorPopUpId = `${workoutId}/${day.id}/editor`;

  const onEdit = () => {
    fns.showPopUp({
      id: editorPopUpId,
      title: "Upravit den",
      noPadding: true,
      content: (
        <DayEditor
          day={day}
          workoutId={workoutId}
          onSave={() => fns.closePopUp(editorPopUpId)}
        />
      ),
    });
  };

  //
  // RENDER
  //
  return (
    <LayoutMain>
      <Wrapper>
        <Content>
          <PageHeader title={day.name ?? "???"} />

          {day.text?.length ? <Info>{day.text}</Info> : null}

          {day.isSickDay ? (
            <SickSection>
              <SickText>Nemoc</SickText>
              <SickIcon>
                <MdSick />
              </SickIcon>
            </SickSection>
          ) : null}

          <Tiles>
            <DayTile
              name="Jídlo"
              progress={progressMeals}
              icon={RiRestaurant2Line}
              linkTo={`${PageSlugs.dayMeals
                .replace(":workoutId", workoutId)
                .replace(":dayId", dayId)}`}
            />

            <DayTile
              name="Suplementy"
              progress={progressSupplements}
              icon={TbPill}
              linkTo={`${PageSlugs.daySupplements
                .replace(":workoutId", workoutId)
                .replace(":dayId", dayId)}`}
            />

            {isRestDay ? (
              <DayTile name="Rest Day" icon={GiNightSleep} />
            ) : (
              <DayTile
                name="Trénink"
                progress={progressWorkout}
                icon={IoFitness}
                linkTo={`${PageSlugs.dayWorkout
                  .replace(":workoutId", workoutId)
                  .replace(":dayId", dayId)}`}
              />
            )}
          </Tiles>
        </Content>

        <Footer>
          <Button
            icon={MdModeEdit}
            label="upravit"
            onClick={onEdit}
            small
            faded
          />
        </Footer>
      </Wrapper>
    </LayoutMain>
  );
};

export default DayPage;
