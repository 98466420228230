import LayoutCentered from "layouts/LayoutCentered";
import styled from "styled-components";

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
`;

const Title = styled.div`
  color: ${(props) => props.theme.colors.main}AA;
  font-size: 80px;
  font-weight: 400;
`;

const Text = styled.div`
  color: ${(props) => props.theme.colors.text}44;
  font-size: 12px;
  font-weight: 400;
  text-transform: uppercase;
`;

const NotFoundPage = () => {
  //
  // RENDER
  //
  return (
    <LayoutCentered>
      <Content>
        <Title>404</Title>
        <Text>stránka nenalezena</Text>
      </Content>
    </LayoutCentered>
  );
};

export default NotFoundPage;
