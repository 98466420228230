import styled from "styled-components";

const Wrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  padding: 10px;
  padding-bottom: 40px;

  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 14px;

  overflow-x: hidden;
  overflow-y: auto;
`;

const Box = styled.div`
  cursor: pointer;
  flex: 1;

  padding: 10px 15px;
  border-radius: 4px;

  background: ${(props) => props.theme.colors.text}11;
  color: ${(props) => props.theme.colors.text}AA;

  white-space: pre-wrap;
  font-family: inherit;
  font-size: 15px;
  font-weight: 300;
  line-height: 150%;
  text-align: left;
`;

const MealSuggestions = (props: {
  suggestions: string[];
  onSuggestionSelected: (v: string) => void;
}) => {
  const { suggestions, onSuggestionSelected } = props;

  //
  // RENDER
  //
  return (
    <Wrapper>
      {suggestions?.map((s, i) => (
        <Box key={i} onClick={() => onSuggestionSelected(s)}>
          {s}
        </Box>
      ))}
    </Wrapper>
  );
};

export default MealSuggestions;
