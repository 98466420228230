import BooleanInput from "components/Inputs/BooleanInput";
import Button from "components/Button";
import { DayType } from "types";
import { RiSaveFill } from "react-icons/ri";
import TextInput from "components/Inputs/TextInput";
import styled from "styled-components";
import { useGlobalStorage } from "context/GlobalStorage";
import { useState } from "react";

export const EXERCISE_OPEN_DURATION_SEC = 0.3;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const Scroll = styled.div`
  flex: 1;

  overflow-x: hidden;
  overflow-y: auto;

  padding: 12px;
  padding-top: 20px;
  padding-bottom: 80px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 20px;
`;

const SectionName = styled.div`
  text-align: center;
  font-family: inherit;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.1em;
  text-align: center;
  text-transform: uppercase;

  margin-top: 40px;
  color: ${(props) => props.theme.colors.text}AA;
`;

const Footer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  padding: 16px 12px;
`;

const DayEditor = (props: {
  workoutId: string;
  day: DayType;
  onSave?: () => void;
}) => {
  const { workoutId, onSave } = props;

  const [day, setDay] = useState<DayType>({ ...props.day });

  const { fns } = useGlobalStorage();

  //
  // DATA
  //
  const saveChanges = () => {
    fns.updateDay({
      workoutId,
      dayId: day.id,
      newDay: day,
    });

    onSave?.();
  };

  const updateDay = (d: Partial<DayType>) => {
    setDay({ ...day, ...d });
  };

  //
  // RENDER
  //
  return (
    <Wrapper>
      <Scroll>
        <TextInput
          label={`Název`}
          value={day.name}
          onChange={(v) => updateDay({ name: v })}
        />

        <TextInput
          label={`Popis`}
          value={day.text}
          onChange={(v) => updateDay({ text: v })}
          textarea
        />

        <SectionName>Ostatní</SectionName>
        <BooleanInput
          label={`Nemoc`}
          value={day.isSickDay}
          onChange={(v) => updateDay({ isSickDay: v })}
        />
      </Scroll>

      <Footer>
        <Button icon={RiSaveFill} label="Uložit" onClick={saveChanges} />
      </Footer>
    </Wrapper>
  );
};

export default DayEditor;
