import { Route, Routes } from "react-router-dom";

import NotFoundPage from "pages/NotFoundPage";
import PublicDataPreview from "./PublicDataPreview";
import PublicMeals from "./PublicMeals";

export const PublicPageSlugs = {
  publicDataPreviewCurrent: "public/data-preview",
  publicDataPreview: "public/data-preview/:googleFileId",
  publicMealsCurrent: "public/meals",
  publicMeals: "public/meals/:googleFileId",
};

const PublicPages = () => {
  return (
    <Routes>
      <Route
        path={PublicPageSlugs.publicDataPreviewCurrent}
        element={<PublicDataPreview />}
      />

      <Route
        path={PublicPageSlugs.publicDataPreview}
        element={<PublicDataPreview />}
      />

      <Route
        path={PublicPageSlugs.publicMealsCurrent}
        element={<PublicMeals />}
      />
      <Route path={PublicPageSlugs.publicMeals} element={<PublicMeals />} />

      {/* 404 Route (fallback) */}
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

export default PublicPages;
