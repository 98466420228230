import styled, { css } from "styled-components";

const Wrapper = styled.div<{ $background?: string }>`
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: auto;

  display: flex;
  flex-direction: column;
  align-items: center;

  ${(props) =>
    props.$background
      ? css`
          background: ${props.$background};
        `
      : css`
          background: none;
        `}
`;

const Content = styled.div`
  box-sizing: border-box;
  width: 100%;
  max-width: ${(props) => props.theme.layout.maxWidth};
  min-height: 100%;
  padding: 10px 14px;

  padding-top: 60px; // space for app header
`;

const LayoutMain = (props?: { background?: string; children?: any }) => {
  return (
    <Wrapper $background={props.background}>
      <Content>{props?.children}</Content>
    </Wrapper>
  );
};

export default LayoutMain;
