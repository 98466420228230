import { IoFitness } from "react-icons/io5";
import LayoutMain from "layouts/LayoutMain";
import LinkBox from "components/LinkBox";
import { LuHistory } from "react-icons/lu";
import PageHeader from "components/PageHeader";
import { PublicPageSlugs } from "pages-public";
import { RiRestaurant2Line } from "react-icons/ri";
import styled from "styled-components";
import { useGlobalStorage } from "context/GlobalStorage";

const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 10px;
`;

const SectionTitle = styled.div`
  flex: 1;
  color: ${(props) => props.theme.colors.text}AA;
  font-size: 17px;
  font-weight: 400;

  margin-top: 24px;
`;

const OverviewsPage = () => {
  const { data } = useGlobalStorage();

  //
  // RENDER
  //
  return (
    <LayoutMain>
      <PageHeader title={`Přehledy`} />

      <Content>
        <LinkBox
          label={"Přehled jídel"}
          icon={RiRestaurant2Line}
          to={"/" + PublicPageSlugs.publicMealsCurrent}
          publicLink
          small
        />
        <LinkBox
          label={"Aktuální plán"}
          icon={IoFitness}
          to={"/" + PublicPageSlugs.publicDataPreviewCurrent}
          publicLink
          small
        />

        <SectionTitle>Historie:</SectionTitle>

        {data?.history?.map((h, i) => (
          <LinkBox
            key={i}
            label={`${h.dates.start} - ${h.dates.end}`}
            icon={LuHistory}
            publicLink
            small
            to={
              "/" +
              PublicPageSlugs.publicDataPreview.replace(
                ":googleFileId",
                h.googleFileId
              )
            }
          />
        ))}
      </Content>
    </LayoutMain>
  );
};

export default OverviewsPage;
