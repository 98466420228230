import styled, { css } from "styled-components";

import { IconType } from "react-icons";

const Wrapper = styled.button<{
  $small?: boolean;
  $faded?: boolean;
  $align?: "left" | "right" | "center";
  disabled?: boolean;
  $warning?: boolean;
}>`
  cursor: pointer;
  outline: none;
  border: none;
  padding: 0;
  margin: 0;
  background: none;

  display: flex;
  align-items: center;
  text-decoration: none;
  gap: 8px;

  font-family: inherit;
  font-size: 16px;
  font-weight: 400;

  ${(props) =>
    props.$warning
      ? css`
          color: ${(props) => props.theme.colors.warning};
          border: 1px solid ${(props) => props.theme.colors.warning}88;
        `
      : css`
          color: ${(props) => props.theme.colors.main}AA;
          border: 1px solid ${(props) => props.theme.colors.main}33;
        `}

  border-radius: 4px;
  padding: 10px 14px;

  &:hover {
    background: ${(props) => props.theme.colors.main}06;
  }

  svg {
    font-size: 15px;
  }

  ${(props) =>
    props.$align === "left"
      ? css`
          text-align: left;
          justify-content: flex-start;
        `
      : props.$align === "right"
      ? css`
          text-align: right;
          justify-content: flex-end;
        `
      : css`
          text-align: center;
          justify-content: center;
        `}

  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.7;
    `}

  ${(props) =>
    props.$small &&
    css`
      font-size: 14px;
    `}

${(props) =>
    props.$faded &&
    css`
      font-weight: 300;
      color: ${(props) => props.theme.colors.text}77;
      border-color: ${(props) => props.theme.colors.text}22;
    `}
`;

const Button = (props: {
  label?: string;
  icon?: IconType;
  align?: "left" | "right" | "center";
  small?: boolean;
  faded?: boolean;
  disabled?: boolean;
  warning?: boolean;
  onClick?: () => void;
  children?: any;
}) => {
  const {
    label,
    align = "center",
    icon: Icon,
    disabled,
    small,
    warning,
    faded,
    onClick,
  } = props;

  return (
    <Wrapper
      onClick={onClick}
      disabled={disabled}
      $small={small}
      $faded={faded}
      $align={align}
      $warning={warning}
    >
      {Icon ? <Icon /> : null}
      {label ?? null}
      {props.children}
    </Wrapper>
  );
};

export default Button;
