import styled, { css } from "styled-components";

const Wrapper = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: auto;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Content = styled.div<{ $wide: boolean }>`
  box-sizing: border-box;
  width: 100%;

  ${(props) =>
    props.$wide
      ? css`
          max-width: ${(props) => props.theme.layout.maxWidthWide};
        `
      : css`
          max-width: ${(props) => props.theme.layout.maxWidth};
        `}
`;

const LayoutPublic = (props?: { wide?: boolean; children?: any }) => {
  const { wide } = props;

  return (
    <Wrapper>
      <Content $wide={wide}>{props?.children}</Content>
    </Wrapper>
  );
};

export default LayoutPublic;
