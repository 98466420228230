import Button from "components/Button";
import { IoNuclear } from "react-icons/io5";
import TextInput from "components/Inputs/TextInput";
import styled from "styled-components";
import { useGlobalStorage } from "context/GlobalStorage";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

export const EXERCISE_OPEN_DURATION_SEC = 0.3;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const Scroll = styled.div`
  flex: 1;

  overflow-x: hidden;
  overflow-y: auto;

  padding: 20px;

  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 20px;
`;

const Inputs = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 10px;
  margin-top: 10px;
  margin-bottom: 20px;
`;

const Section = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  font-size: 15px;
  line-height: 1.4;
  white-space: pre-wrap;
  text-align: left;

  color: ${(props) => props.theme.colors.text}99;

  strong {
    font-weight: 400;
    color: ${(props) => props.theme.colors.warning};
  }

  ul,
  ol {
    margin: 0;
    padding: 0;
    padding-top: 10px;
    padding-left: 18px;
  }

  li {
    margin: 0;
    padding: 0;
    padding-bottom: 5px;
    font-size: 14px;
    font-weight: 300;
  }
`;

const Footer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  padding: 16px 12px;
`;

const ConfirmResetData = (props: { onConfirm?: () => void }) => {
  const { onConfirm } = props;

  const { fns, data } = useGlobalStorage();
  const navigate = useNavigate();

  const confirm = () => {
    fns.resetCurrentPlanRaw();
    onConfirm?.();
    navigate("/");
  };

  const currentPlan = data.workoutPlans[0];

  const [dateStart, setDateStart] = useState(currentPlan?.dates?.start);
  const [dateEnd, setDateEnd] = useState(currentPlan?.dates?.end);
  const [planText, setPlanText] = useState(currentPlan?.text);

  //
  // RENDER
  //
  return (
    <Wrapper>
      <Scroll>
        <Section>
          <strong>Resetování nenávratně smaže uložená data!</strong>
        </Section>

        <Section>
          <span>Před resetováním by měly být provedeny tyto operace:</span>
          <ul>
            <li>
              Zkopírovat aktuální data.json v Google Drive do složky s historií.
            </li>
            <li>
              Vygenerovat pro tento soubor shared-link, a z něj vzít id souboru.
            </li>
            <li>
              Id souboru přidat do položky "history" uvnitř data.json (lze přímo
              v json editoru zde v appce, tato data nebudou smazána)
            </li>
          </ul>
        </Section>

        <Section>
          Nastavit nový plán na:
          <Inputs>
            <TextInput
              label={`začátek`}
              value={dateStart}
              onChange={setDateStart}
            />
            <TextInput label={`konec`} value={dateEnd} onChange={setDateEnd} />
            <TextInput label={`text`} value={planText} onChange={setPlanText} />
          </Inputs>
        </Section>

        <Section>
          <strong>
            <span>Reset provede:</span>
            <ul>
              <li>
                <strong>
                  Přesune hodnotu konečné váhy do počáteční váhy, a konečnou
                  váhu smaže.
                </strong>
              </li>
              <li>
                <strong>
                  Smaže všechna vyplněná jídla a nastaví
                  'isCompleted&nbsp;=&nbsp;false'
                </strong>
              </li>
              <li>
                <strong>
                  Smaže všechny vyplněné suplementy a nastaví
                  'isCompleted&nbsp;=&nbsp;false'
                </strong>
              </li>
              <li>
                Pro všechny cviky nastaví 'isCompleted&nbsp;=&nbsp;false',
                hodnoty zůstanou zachované pro další týden
              </li>
            </ul>
          </strong>
        </Section>
      </Scroll>

      <Footer>
        <Button icon={IoNuclear} label="Resetovat" onClick={confirm} warning />
      </Footer>
    </Wrapper>
  );
};

export default ConfirmResetData;
