import { StorageType, useGlobalStorage } from "context/GlobalStorage";
import {
  downloadJsonFile,
  downloadTxtFile,
  getValidToken,
  googleDrive_uploadJsonFile,
} from "utils";

import { AiOutlineDownload } from "react-icons/ai";
import Button from "components/Button";
import { FaGoogleDrive } from "react-icons/fa";
import { FaUser } from "react-icons/fa";
import { IoNuclear } from "react-icons/io5";
import LayoutMain from "layouts/LayoutMain";
import LinkBox from "components/LinkBox";
import { MdFlight } from "react-icons/md";
import PageHeader from "components/PageHeader";
import { PageSlugs } from "pages";
import WORKOUT_PLAN from "assets/WORKOUT_PLAN_JSON_GENERATOR";
import styled from "styled-components";
import { useState } from "react";

const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 14px;
  padding-bottom: 25vh;
`;

const Section = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 14px;

  background: ${(props) => props.theme.colors.text}04;
  border-radius: 4px;
  padding: min(8%, 20px) min(4%, 20px);

  box-shadow: 0 4px 12px 0px ${(props) => props.theme.colors.backgroundDark}44;
`;

const SubSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 8px;
`;

const SectionTitle = styled.div`
  flex: 1;
  color: ${(props) => props.theme.colors.text}AA;
  font-size: 17px;
  font-weight: 400;
`;

const SectionSubTitle = styled.div`
  flex: 1;
  color: ${(props) => props.theme.colors.text}AA;
  font-size: 15px;
  font-weight: 400;
  margin-top: 10px;
`;

const SectionText = styled.div`
  flex: 1;
  color: ${(props) => props.theme.colors.text}88;
  font-size: 15px;
  white-space: pre-wrap;

  ul,
  ol {
    margin: 0;
    padding: 0;
    padding-left: 18px;
  }

  li {
    margin: 0;
    padding: 0;
    padding-bottom: 5px;
  }
`;

const ButtonNote = styled.span`
  opacity: 0.5;
`;

const SettingsPage = () => {
  const { data, fns, isOffline } = useGlobalStorage();

  const [isUploading, setIsUploading] = useState(false);

  //
  // UTILS
  //
  const handleUpload = async () => {
    try {
      setIsUploading(true);
      const jsonData = WORKOUT_PLAN;
      const fileName = "example.json";
      const accessToken = await getValidToken();
      const data = await googleDrive_uploadJsonFile(
        accessToken,
        fileName,
        jsonData
      );
      console.info("Uploaded File:", data);
    } catch (error) {
      console.error("Upload failed:", error);
    } finally {
      setIsUploading(false);
    }
  };

  //
  // RENDER
  //
  return (
    <LayoutMain>
      <PageHeader title={`Nastavení`} />

      <Content>
        <Section>
          <SectionTitle>Google</SectionTitle>

          <Button
            onClick={() => {
              fns.signOut();
            }}
            icon={FaUser}
            small
            align="left"
          >
            Odhlásit se
          </Button>

          <Button
            onClick={() => {
              fns.loadData({ storageType: StorageType.googleDrive });
            }}
            icon={FaGoogleDrive}
            small
            align="left"
          >
            Aktualizovat z Google Drive
          </Button>

          <Button
            onClick={() => {
              fns.saveData({ storageType: StorageType.googleDrive });
            }}
            icon={FaGoogleDrive}
            small
            align="left"
          >
            Uložit na Google Drive
          </Button>
        </Section>

        <Section>
          <SectionTitle>Data</SectionTitle>

          <Button
            onClick={() => {
              fns.resetCurrentPlan();
            }}
            icon={IoNuclear}
            small
            align="left"
          >
            Resetovat aktuální plán
          </Button>

          <Button
            onClick={() => {
              downloadJsonFile({ data, filename: "fitness-tracker-data.json" });
            }}
            icon={AiOutlineDownload}
            small
            align="left"
          >
            Stáhnout .json <ButtonNote>(raw data)</ButtonNote>
          </Button>

          <Button
            onClick={() => {
              downloadTxtFile({
                data: fns.getDataAsString(),
                filename: "fitness-tracker-data.txt",
              });
            }}
            icon={AiOutlineDownload}
            small
            align="left"
          >
            Stáhnout .txt <ButtonNote>(editovatelný výpis)</ButtonNote>
          </Button>
        </Section>

        <Section>
          <SectionTitle>Offline režim</SectionTitle>

          <Button
            onClick={() => fns.setOfflineMode(!isOffline)}
            icon={MdFlight}
            label={`${isOffline ? `Vypnout` : `Zapnout`}`}
          />

          <SubSection style={{ opacity: !isOffline ? 1 : 0.5 }}>
            <SectionSubTitle>vypnuto:</SectionSubTitle>
            <SectionText>
              <ul>
                <li>data se ukládají do google drive po každé změně</li>
                <li>
                  auto-reload po delší neaktivitě pro zajištění konzistence dat
                  napříč zařízeními
                </li>
              </ul>
            </SectionText>
          </SubSection>

          <SubSection style={{ opacity: isOffline ? 1 : 0.5 }}>
            <SectionSubTitle>zapnuto:</SectionSubTitle>
            <SectionText>
              <ul>
                <li>data se ukládají do local storage po každé změně</li>
                <li>žádný auto-reload, není potřeba</li>
              </ul>
            </SectionText>
          </SubSection>
        </Section>

        <Section>
          <SectionTitle>Dev</SectionTitle>

          <LinkBox label={`Json Data Editor`} to={PageSlugs.dataJsonEditor} />

          <Button
            onClick={handleUpload}
            disabled={isUploading}
            icon={FaGoogleDrive}
            small
            align="left"
            label={
              isUploading
                ? "Uploading..."
                : "Upload example JSON file to Google Drive (root folder)"
            }
          />
        </Section>
      </Content>
    </LayoutMain>
  );
};

export default SettingsPage;
