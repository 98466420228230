import { DayType } from "types";

export const getMealsProgress = (day: DayType) => {
  let progress = 0;
  let total = 0;

  day.meals?.forEach((meal) => {
    const isCompleted =
      meal.customOption?.isCompleted ||
      !!meal.options?.find((mealOption) => mealOption.isCompleted);
    if (isCompleted) {
      progress += 1;
    }
    total += 1;
  });

  return total ? Math.round((progress / total) * 100) / 100 : 0;
};

export const getMealNameFromId = (id: string) => {
  switch (id) {
    case "breakfast":
      return "Snídaně";
    case "large-meal-1":
      return "Velké jídlo 1";
    case "large-meal-2":
      return "Velké jídlo 2";
    case "pre-workout":
      return "Před tréninkem";
    case "post-workout":
      return "Po tréninku";
    case "supper":
      return "večeře";
    default:
      return id;
  }
};
